(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('OutBoundDetailsController', OutBoundDetailsController);

    OutBoundDetailsController.$inject = ['$scope', 'Principal', '$state', 'MessageService', '$localStorage', '$stateParams', '$rootScope', '$uibModal','OutBound','FindWarehouse','$http','DateUtils'];

    function OutBoundDetailsController ($scope, Principal,  $state, MessageService, $localStorage, $stateParams, $rootScope, $uibModal,OutBound,FindWarehouse,$http,DateUtils) {
        var vm = this;
        // 判断是否具有当前页面的访问权限
        Principal.hasAuthority('ROLE_SELF_SUPPORT_ORDER').then(function (data) {
            if (!data) {
                MessageService.error("不好意思，你不具有订单访问的权限！");
                $state.go("home");
            }
        });
        vm.token = $localStorage.authenticationToken;
        vm.searchWarehouse = searchWarehouse;


        init()

        function init(){
            OutBound.get({id : $stateParams.id},function(res){
                vm.data = res
                // 默认选中当前商品的第一个仓库并查询库存
                if(vm.data.status === 'UnShippedOut'){
                    vm.data.lines.forEach(function(item,index){
                        item.outNum = item.num
                        item.warehouseId = item.warehouses[0].id
                        searchWarehouse(item)
                    })
                }

            },function(res){
                MessageService.error("查询失败，请重试！");
            })
        }

        //    返回   保留筛选信息
        $scope.goBack = function(){
            if($rootScope.previousState){
                $state.go($rootScope.previousState.name,{
                    page: $stateParams.page,
                    state: $stateParams.state,
                    stockOrderNumber: $stateParams.stockOrderNumber,
                    startDate: $stateParams.startDate,
                    endDate: $stateParams.endDate,
                    back: $stateParams.back,
                    number: $stateParams.number,
                    storeName: $stateParams.storeName,
                });
            }else {
                history.back();
            }
        };
        // 出库
        $scope.outBound = function(){
            console.log(vm.data);
            if(valiData()){
                 MessageService.confirm("是否确认出库？", function () {
                    OutBound.outBound(vm.data, function () {
                        MessageService.success("出库成功");
                        history.back();
                }, function (error) {
                        MessageService.error("出库失败，请重试！");
                    });
                });
            }
        };

        // 关闭出库单
        $scope.close = function(){
            $uibModal.open({
                templateUrl: 'app/inventoryManagement/components/close-outBount-dialog.html',
                controller: 'CloseOutBoundDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function () {
                        return vm.data.id
                    }]
                }
            }).result.then(function (reason) {
                OutBound.close({id:vm.data.id,reason:reason}, function () {
                    MessageService.success("关闭成功");
                    history.back();
                }, function (error) {
                    MessageService.error("关闭失败，请重试！");
                });
            });
        };

        // 查库存
        function searchWarehouse(item,index){
            // 仓库名赋值
            item.warehouses.forEach(function(itm){
                if(itm.id === item.warehouseId) item.warehouseName = itm.name
            })
            var params = {
                warehouseId:item.warehouseId,
                productNumber:item.scmNumber,
            }
            if(!item.warehouseId) {
                item.inventoryDesc = ''
                return
            }
            FindWarehouse.searchByidAndNumber(params, function (res) {
                if(res.message && res.message.usableQuantity !== null){
                    item.inventoryDesc = res.message.usableQuantity
                }else{
                    item.inventoryDesc = ''
                }
            }, function (error) {
                MessageService.error("查询失败，请重试！");
            });
        };

        // 出库数量是否大于库存
        $scope.checkNum = function(itm,num){
            // console.log(itm.inventoryDesc);
            // if(itm.inventoryDesc === 0){
            //     MessageService.error("该仓库库存不足");
            //     itm.outNum  = null
            //     return
            // }
            if(itm.inventoryDesc === '' || itm.inventoryDesc === null){
                MessageService.error("请先选择出库仓库");
                itm.outNum  = null
                return
            }
            if(num > itm.inventoryDesc){
                MessageService.error("出库数量不能大于库存数量");
                itm.outNum  = null
                return
            }
        };

        // 单价格式限制 大于等于0 小数点后两位
        $scope.checkPrice = function(itm,num){
            if(!num){
                itm.price = 0
                return
            }else{
                num = num.toString()
            }
            const regex = /^(?:0|[1-9]\d*)(?:\.\d{1,2})?$/;

            if (!regex.test(num)) {
                if (num.startsWith('-')) {
                    MessageService.error('单价不能小于0')
                    itm.price = 0
                    return
                }
                const cleaned = num.replace(/[^\d.]/g, '');
                if (cleaned === '') return '';

                const parts = cleaned.split('.');
                var integerPart = parts[0];

                var decimalPart = parts.length > 1 ? parts[1].slice(0, 2) : '';
                if (integerPart === '') {
                    integerPart = '0';
                }
                if (decimalPart.length > 2) {
                    decimalPart = decimalPart.slice(0, 2);
                }
                var _num =  integerPart + decimalPart ? '.' + decimalPart : ''
                num = Number(_num)
                itm.price = num
            }
        };

        // 处理差异
        $scope.handleDifference = function(item){
            MessageService.confirm("是否确认生成门店入库差异单?", function () {
                OutBound.checkDiff({inventoryStockOutId: vm.data.id}, function () {
                    init();
                    MessageService.success("生成成功");
                }, function (error) {
                    MessageService.error("生成失败，请重试！");
                });
            });
        }

        $scope.export = function(type){
            $http({
                url: 'api/inventory-stock-out/exportInventoryStockOutDetail/'+vm.data.id+'?reportType=' + type,
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var tableName = type === 'storeBill' ? "门店账单" : "仓库成本"
                var filename = vm.data.number + tableName + DateUtils.convertLocalDateToServer(Date.now())+ ".xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (data, status, headers, config) {
            });
        }

        // 出库数量不能为空判断
        function valiData(){
            var flag = true
            vm.data.lines.forEach(function(item){
                if(flag){
                    if(item.outNum === null){
                        MessageService.error("出库数量不能为空")
                        flag = false
                    }
                    if(item.outNum > item.inventoryDesc){
                        MessageService.error("出库数量不能大于现有库存数量")
                        flag = false
                    }
                }

            })
            return flag
        }

        // 去采购
        $scope.jump = function(){
            $state.go('purchaseProductLineAdd');
        };

        $scope.getStatus = function(item){
            switch (item) {
                case 'UnShippedOut':
                    return '未出库';
                case 'ShippedOut':
                    return '已出库';
                case 'ShippedIn':
                    return '已接收';
                case 'Close':
                    return '已关闭';
                default:
                    return item
            }
        }
    }
})();
